import { defineStore } from 'pinia'
// import Swal from 'sweetalert2'
import FieldsService from '@/apps/dailyTech/inventario-ti/services/fields.service'

const _FieldsService = new FieldsService()

export const useInfoInventoryStore = defineStore({
  id: 'infoInventoryStore',
  state: () => ({
    infoDevice: {},
    fields: {
      headers: {},
      features: []
    },
    dropdownOptionsDinamic: {},
    infoInventory: {},
    isValidForm: true
  }),
  getters: {
    _getInfoDevice: (state) => state.infoDevice,
    _getFields: (state) => state.fields,
    _getInfoInventory: (state) => state.infoInventory,
    _getIsValidForm: (state) => state.isValidForm
  },
  actions: {
    _setInfoDevice (payload) {
      this.infoDevice = payload
    },
    _setFields (payload) {
      this.fields = payload
    },
    _setInfoInventory (payload) {
      this.infoInventory = payload
    },
    _setIsValidForm (payload) {
      this.isValidForm = payload
    },
    _setDropdownOptionsDinamic (payload) {
      this.dropdownOptionsDinamic = payload
    },
    /**
     * Limpiar el store
     */
    _clearStoreInventory () {
      this.infoDevice = {}
      this.fields = {
        headers: {},
        features: []
      }
      this.dropdownOptionsDinamic = {}
      this.infoInventory = {}
      this.isValidForm = true
    },
    /**
     * Acción para obtener los campos de un dispositivo y guardarlos en el store
     * @param infoDevice
     * @return {Promise<void>}
     * @private
     */
    async _actionFieldsByDevice (infoDevice) {
      try {
        const response = await _FieldsService.getFieldsByDevice({ id: infoDevice.id })
        this._setFields(response.data)
        this._setInfoDevice(infoDevice)
      } catch (error) {
        console.error('Error al obtener los campos del dispositivo', error)
      }
    },
    /**
     * Acción para obtener las opciones de los dropdown tipo select o lista y guardarlas en el store
     * @param fields
     * @return {Promise<void>}
     * @private
     */
    async _actionGetDropdownOptionsDinamic (fields) {
      try {
        this.dropdownOptionsDinamic = {}
        const fieldsNeedingOptions = fields.filter(field => ['select', 'checkbox', 'radio', 'multiselect'].includes(field.dataType))

        for (const field of fieldsNeedingOptions) {
          const options = field.choiceList
          this._setDropdownOptionsDinamic({ ...this.dropdownOptionsDinamic, [field.nameField]: options })
        }
      } catch (error) {
        console.error('Error al obtener las opciones del dropdown -->', error)
      }
    }
  }
})
