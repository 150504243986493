<script setup>
import { onMounted, ref } from 'vue'
import Icon from '@/components/Icon/index.vue'
import { useInfoInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/infoInventory.store'
import { useViewEditInfoInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/viewEditInfoInventory.store'

const infoInventoryStore = useInfoInventoryStore()
const viewEditInfoInventoryStore = useViewEditInfoInventoryStore()
const items = ref([
  {
    route: { name: 'daily-tech.inventario-ti.configuracion.dispositivos' },
    name: 'Tipos de Dispositivos',
    description: 'Personaliza los diferentes tipos de dispositivos.',
    icon: 'flat-color-icons:multiple-devices'
  },
  {
    route: { name: 'daily-tech.inventario-ti.configuracion.marcas' },
    name: 'Marcas',
    description: 'Personaliza las marcas de los dispositivos.',
    icon: 'flat-color-icons:registered-trademark'
  },
  {
    route: { name: 'daily-tech.inventario-ti.configuracion.modelos' },
    name: 'Modelos',
    description: 'Personaliza los modelos de los dispositivos.',
    icon: 'flat-color-icons:template'
  },
  {
    route: { name: 'daily-tech.inventario-ti.configuracion.ubicaciones' },
    name: 'Ubicaciones',
    description: 'Personaliza las ubicaciones de los dispositivos.',
    icon: 'fxemoji:cityscape'
  },
  {
    route: { name: 'daily-tech.inventario-ti.configuracion.owners' },
    name: 'Dueños',
    description: 'Personaliza los propietarios de los dispositivos.',
    icon: 'fluent-emoji-flat:card-index'
  },
  {
    route: { name: 'daily-tech.inventario-ti.configuracion.estados' },
    name: 'Estados',
    description: 'Personaliza los estados de los dispositivos.',
    icon: 'flat-color-icons:services'
  },
  {
    route: { name: 'daily-tech.inventario-ti.configuracion.campos' },
    name: 'Campos',
    description: 'Personaliza los campos de los dispositivos.',
    icon: 'flat-color-icons:multiple-inputs'
  }
])

// Hook onMounted
onMounted(async () => {
  // Limpiar viewEditInfoInventoryStore
  viewEditInfoInventoryStore._actionClearViewEditInfoInventory()
  // Limpiar el store de infoInventory
  infoInventoryStore._clearStoreInventory()
})
</script>

<template>
  <section class="intro-x">
    <Toolbar class="box my-4 py-2 px-4">
      <template #start>
        <h2 class="text-lg font-bold truncate mr-5">Configuración</h2>
      </template>
      <template #end>
        <Button label="Regresar" icon="pi pi-arrow-left" class="p-button-sm" @click="$router.push({ name: 'daily-tech.inventario-ti'})"/>
      </template>
    </Toolbar>

    <div class="grid lg:grid-cols-3 gap-4 my-3 grid-cols-1">
      <router-link
        :to="row.route"
        v-for="(row,k) in items"
        :key="k"
        class="box w-full rounded-3xl p-4 flex justify-center flex-col items-center zoom-in"
      >
        <div class="bg-gray-800 p-2 rounded-xl flex justify-center items-center" style="width: 75px; height: 75px">
          <Icon :icon="row.icon" style="font-size: 3.5rem;" />
        </div>
        <div class="mt-2 mb-1 font-bold text-base">{{row.name}}</div>
        <div class="text-gray-500">{{row.description}}</div>
      </router-link>
    </div>

    <router-view />
  </section>
</template>

<style scoped>

</style>
